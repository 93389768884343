import PocketBase from 'pocketbase';
import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import CreateContent from '../Components/CreateContent';
import CreatePost from '../Components/CreatePost';
import { IconClose } from '../Components/icons';
import ImageUpload from '../Components/ImageUpload';
import ImageView from '../Components/ImageView';
import OverlayWindow from '../Components/OverlayWindow';
import Posts from './Posts';
import MyContext from "../Components/MyContext";
import { IoIosAddCircleOutline } from "react-icons/io";
import CreateText from '../Components/CreateText';

const pb = new PocketBase(process.env.REACT_APP_PKT_URL);


const UserProfile = () => {


    const { user, writer, location, followers, following, followingId } = useContext(MyContext);


    if (!user) {
        return <div className='w-full h-[92vh] bg-black text-white flex justify-center items-center'  >Loading...</div>;
    }


    const mobile = <div className="h-[92vh] overflow-scroll bg-zinc-900 overflow-hidden">

    <div className="w-full h-max ">


                
                {/* {setProfileSetup && <UserProfileSetup/>} */}


            <div className='space-y-3 w-full overflow-scroll py-10 flex flex-col items-center bg-gray-000 border-l border-gray-700'>


                {writer &&

                    <div className='w-[80vw] h-auto bg-white p-5 rounded-md '>
                        <div className='flex flex-col justify-center items-center  space-y-4'>
                            <p><strong>Profile</strong></p>


                            {writer.profile_image == '' 
                                ? <ImageUpload collection={writer.collectionId} id={writer.id} imageField='profile_image' /> 
                                : <ImageView collectionId={writer.collectionId} id={writer.id} image={writer.profile_image} type='' />
                            }

                            <p>{user.expand && user.expand.writer_id && user.expand.writer_id.name}</p>
                            <Link to={`/profile/${writer.id}`}>View Profile</Link>
                        </div>
                    </div>

                }

                <div className='w-[80vw] h-auto bg-white p-5 rounded-md'>
                    <div className='flex flex-col justify-center items-center  space-y-4'>
                        <FollowView data={followers} buttonName='Followers' />
                        <FollowView data={following} buttonName='Following' />
                    </div>
                </div>

                <div className='w-[80vw] h-auto bg-white p-5 rounded-md flex justify-center'>
                    <Link className='text-center' to={`/collection/posts`}>Collection</Link>
                </div>
                
                <div className='w-[80vw] h-auto bg-white p-5 rounded-md flex justify-center'>
                    <Link className='text-center' to={`/profile/edit/${user.writer_id}`}>Edit Profile</Link>
                </div>

            </div>
    </div>



    {/*Center*/}

    <div className='flex flex-col h-max w-[100vw]'>
        <div className=' h-[20vh] flex justify-center items-center'>
            <CreatePost />
            <div className='bg-red text-black rounded-full size-[3.5vw] '>
                <OverlayWindow btnName={<IoIosAddCircleOutline />} > 
                    <CreateContent/>
                    <CreateText/>

                </OverlayWindow>
            </div>
        </div>
        <div className=" w-full bg-red-000 flex justify-center items-center ">
            <Posts data1={followingId} Fetchtype='feed' />
        </div>
    </div>


    <div className=" p-8 bg-gray-000 rounded shadow-md  h-screen overflow-scroll border-l border-gray-700">
    </div>



</div>;















    const desktop = <div className="h-[92vh] grid grid-cols-4 bg-zinc-900 overflow-hidden">


{/* {writer && (
    !writer.username || 
    !writer.image || 
    !writer.gender || 
    !writer.location
) && <UserProfileSetup setProfileSetup={setProfileSetup} data={writer} />} */}


            <div className="w-full h-[92vh] overflow-scroll border-r border-gray-700">
                <div className="w-full h-full flex flex-col  justify-center items-center space-y-4 bg-gray-000 overflow-hidden">


                    <div className='space-y-3 w-full overflow-scroll py-10 flex flex-col items-center bg-gray-000 border-l border-gray-700'>


                        {writer &&

                            <div className='w-[20vw] h-auto bg-white p-5 rounded-md '>
                                <div className='flex flex-col justify-center items-center  space-y-4'>
                                    <p><strong>Profile</strong></p>


                                    {writer.profile_image == '' ? <ImageUpload collection={writer.collectionId} id={writer.id} imageField='profile_image' /> :
                                        <ImageView collectionId={writer.collectionId} id={writer.id} image={writer.profile_image} type='' />
                                    }

                                    <p>{user.expand && user.expand.writer_id && user.expand.writer_id.name}</p>
                                    <Link to={`/profile/${writer.id}`}>View Profile</Link>
                                </div>
                            </div>

                        }

                        <div className='w-[20vw] h-auto bg-white p-5 rounded-md'>
                            <div className='flex flex-col justify-center items-center  space-y-4'>
                                <FollowView data={followers} buttonName='Followers' />
                                <FollowView data={following} buttonName='Following' />
                            </div>
                        </div>

                        <div className='w-[20vw] h-auto bg-white p-5 rounded-md flex justify-center'>
                            <Link className='text-center' to={`/collection/posts`}>Collection</Link>
                        </div>
                        
                        <div className='w-[20vw] h-auto bg-white p-5 rounded-md flex justify-center'>
                            <Link className='text-center' to={`/profile/edit/${user.writer_id}`}>Edit Profile</Link>
                        </div>

                    </div>
                </div>
            </div>

            {/*Center*/}

            <div className='col-span-2 overflow-hidden flex flex-col h-[92vh] w-[50vw]'>
                <div className='w-full h-[15vh] border-b border-gray-700 flex justify-center items-center'>
                    <CreatePost />
                    <div className='  text-white hover:text-orange-500 duration-200 rounded-full size-[3.5vw] flex justify-center items-center'>
                        <OverlayWindow btnName={<IoIosAddCircleOutline />} > 
                            <CreateContent/>

                        </OverlayWindow>
                    </div>
                </div>
                <div className=" p-8 pb-11 h-[100%] col-span-2 overflow-scroll w-full">
                    <Posts data1={followingId} Fetchtype='feed' />
                </div>
            </div>







            <div className=" p-8 bg-gray-000 rounded shadow-md  h-screen overflow-scroll border-l border-gray-700">
            </div>



        </div>;


    return window.innerWidth <= 426 ? mobile : desktop;


};



























function FollowView({ data, buttonName }) {

    const [open, setOpen] = useState(false);

    const toggleEdit = () => {
        setOpen(!open);
    };


    function WriterCard({ data }) {

        const { name, id, collectionId, profile_image } = data;


        return (
            <div className='w-[85%] h-[15vh] rounded-xl p-1 font bg-white m-1 shrink-0 flex space-y-1 overflow-hidden'>
                <Link to={`/${id}/${name.replace(/\s+/g, '-')}`} className='flex justify-center items-center space-x-5 p-3'>
                    <ImageView collectionId={collectionId} id={id} image={profile_image} type='sm' />
                    <p className='text-md font-semibold text-center'>{name}</p>
                </Link>
            </div>
        );
    }

    return (

        <>

            <button disabled={!data || data.length === 0} className='bg-gray-200 hover:bg-gray-300 px-5 font-semibold py-2 rounded-full' onClick={toggleEdit}>{buttonName} {data && data.length || '0'}</button>


            {open &&

                <div className="fixed bottom-0 left-0 bg-black/90  w-[100vw] h-full flex justify-center items-center backdrop-blur-sm">
                    <div className='flex flex-col h-[70vh] w-[80vw] md:w-[40vw] rounded-md bg-black border border-gray-700'>

                        <div className="flex justify-between items-center bg-slate-000 p-3 border-b border-gray-700">
                            <p className='text-white font-bold'>Following {data && data.length}</p>
                            <button onClick={toggleEdit}>{<IconClose ratio="15" />}</button>
                        </div>

                        <div className="flex items-center bg-gray-000 overflow-scroll bg-pink-000 h-[70vh] w-full flex-col py-5">

                            {data.map((item) => (<WriterCard data={item} />))}
                        </div>


                    </div>

                </div>
            }

        </>
    );
}














export default UserProfile;
