// MyContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import PocketBase from 'pocketbase';
import { useNavigate } from 'react-router-dom';
import { fetchDataWithReferences, fetchDataWithFilter } from '../api/api'; // Adjust the path as necessary



const MyContext = createContext();

const pb = new PocketBase(process.env.REACT_APP_PKT_URL);


export const MyContextProvider = ({ children }) => {
  
    const [logged, setLogged] = useState(false);
    const [success, setSuccess] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const [user, setUser] = useState(null);
    const [writer, setWriter] = useState(null);
    const [location, setLocation] = useState(null);
    const [followers, setFollowers] = useState(null);
    const [following, setFollowing] = useState(null);
    const [followingId, setFollowingId] = useState(null);

    const [profileSetup, setProfileSetup] = useState(false);


    const userId = localStorage.getItem('userId');


    //storage to fetch All Writers for Writer Page
    const [allWriters, setAllWriters] = useState([]);

    //storage to fetch All books for books Page
    const [allBooks, setAllBooks] = useState([]);

    //storage to fetch All books for books Page
    const [allPosts, setAllPosts] = useState([]);

    //storage to fetch All books for books Page
    const [allContent, setAllContent] = useState([]);

    //storage to fetch All books for books Page
    const [allPublishers, setAllPublishers] = useState([]);

    //storage to fetch All books for books Page

    const [language, setLanguage] = useState('hi-t-i0-und');


    // useEffect(()=>{




    // },[language])




    useEffect(() => {
     
     
     //User Data fetch
      const fetchUser = async () => {
          if (userId) {
              try {
                  const userData = await pb.collection('users').getOne(userId, { expand: 'writer_id, writer_id.location, writer_id.followers, writer_id.following' });
                  setUser(userData);
                  
                  // userData.expand.writer_id ? setWriter(userData.expand.writer_id) : setCreate(true) ;
                  setWriter(userData.expand.writer_id);
                  setLocation(userData.expand.writer_id.expand.location);
                  setFollowing(userData.expand.writer_id.expand.following);
                  setFollowers(userData.expand.writer_id.expand.followers);
                  setFollowingId(userData.expand.writer_id.following);

                  localStorage.setItem('writerName', userData.expand.writer_id.name);
                  
                  if (writer && (writer.username === '' || writer.username === undefined)) {
                      setProfileSetup(true);
                  }
                  
                  console.log(userData);
                  console.log(location);
                  console.log(followers);
                  setRefresh(false);

              } catch (err) {
                  console.error('Failed to fetch user data:', err);
              }
          }
      };



      //to fetch all writer for writer page
      async function getAllWriters() {
        const data = await fetchDataWithReferences('writer', 'books, location');
        if (data) {
          setAllWriters(data);
        }
      }
      

      async function getAllBooks() {
        const data = await fetchDataWithReferences('books', 'writer_id,publisher_id, book_image, tags');
        
        if (data) {
          setAllBooks(data);
        }
      }
      
      async function getAllPosts() {
        const data = await fetchDataWithFilter('post', 'user_id, writer_id, like',);
        
        if (data) {
          setAllPosts(data);
        }
      }
      
      async function getAllContent() {
        const data = await fetchDataWithFilter('content', 'user_id, writer_id, like',);
        if (data) {
          setAllContent(data);
        }
      }


      async function getAllPublishers() {
          const data = await fetchDataWithReferences('publisher', 'books, location');
        if (data) {
          setAllPublishers(data);
        }
      }


      getAllPublishers();
      getAllContent();
      getAllPosts();
      getAllBooks();
      getAllWriters();
      fetchUser();
  
    }, [refresh]);























  
    const handleLogin = () => {
    setLogged(true);
  };

  const handleSuccess = () => {
    setSuccess(true);
  };

  const handleSuccessOff = () => {
    setSuccess(false);
  };


  const handleLogout = () => {
    const pb = new PocketBase('https://fileease.pockethost.io/');
    pb.authStore.clear();
    setLogged(false);
    navigate('/login'); // Navigate to the login page
    console.log('Logout success');
  };


  return (
    <MyContext.Provider value={{language, setLanguage, user, writer, location, followers, following, followingId, allWriters, allBooks, allPosts, allContent, allPublishers, refresh, setRefresh, logged, success, handleSuccessOff, handleSuccess, handleLogin, handleLogout }}>
      {children}
    </MyContext.Provider>
  );
};

export default MyContext;
