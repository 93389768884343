import { useState } from "react";
import { IconClose } from "./icons";

function OverlayWindow({ btnName, children, btnStyle }) {
    const [open, setOpen] = useState(false);

    const toggleEdit = () => {
        setOpen((prev) => !prev);
    };

    return (
        <>
            <button onClick={toggleEdit} className={`${btnStyle} text-5xl flex justify-center items-center` }>
                {btnName}
            </button>
            {open && (
                <div className="bg-black/80 backdrop-blur-md w-full h-[100vh] flex justify-center items-center absolute top-0 left-0">
                    {children}
                    <button className="absolute top-10 right-10" onClick={toggleEdit}>
                        <IconClose />
                    </button>
                </div>
            )}
        </>
    );
}

export default OverlayWindow;
