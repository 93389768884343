import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { fetchDataSingle } from '../api/api'; // Adjust the path as necessary
import ImageView from '../Components/ImageView';

import BookCard from '../Components/BookCard';
import PocketBase from 'pocketbase';
import PostView from '../Components/PostView';
import { Children } from 'react';
import CardScrollerSection from '../Components/CardScrollerSection';

const pb = new PocketBase(process.env.REACT_APP_PKT_URL);



function WriterView() {

    const [record, setRecord] = useState({});
    const [books, setBooks] = useState([]);
    const [location, setLocation] = useState([]);
    const [posts, setPosts] = useState([]);
    const [education, setEducation] = useState([]);

    const { writerId } = useParams();


    useEffect(() => {

        async function getData() {

            const data = await fetchDataSingle('writer', writerId, 'books, location, education, user_id');

            if (data.expand) {

                setRecord(data);
                data.expand.books && setBooks(data.expand.books);
                data.expand.location && setLocation(data.expand.location);
                data.expand.education && setEducation(data.expand.education);

                console.log(data);
                console.log('eee', data.expand.books);

            }
        }
        getData();
    }, []);


    const {
        name,
        age,
        user_id,
        about,
        gender,
        profile_image,
        type,
        expand,
        collectionId,
        id,
    } = record;


    const imageUrl = `${process.env.REACT_APP_PKT_URL}/api/files/${collectionId}/${id}/${profile_image}`;


    return (
        <div className='w-full h-[92vh] bg-zinc-900 overflow-hidden'>


            <div className='bg-gray-200 w-full h-full flex  justify-center overflow-scroll rounded-0'>

                <div className='bg-zinc-900 w-[100%] my-0 pb-20 h-max  px-[20%] font-serif  space-y-5 rounded-0'>

                    <div className=' items-center flex flex-col pb-10 space-y-4 text-center h-auto rounded-b-2xl'>

                        {/* <ImageView collectionId={collectionId} id={id} image={profile_image} type='profile' /> */}
                        <img src={imageUrl} alt='' className='cursor-pointer object-cover size-[50vh] bg-gray-200 rounded-0 shrink-0' />
                        <p className='text-4xl font-semibold'>{name}</p>
                        {/* <p className='text-4xl font-semibold'>{age == '' ? null : age}</p> */}
                        {/* <p className='text-4xl text-black font-semibold'>{location.village}, { location.city}</p> */}


                    </div>


                    <div className='bg-white flex flex-col items-center space-y-4 pt-10 py-10 rounded-2xl'>
                        <Heading text='About' />
                        <p className='mx-20 text-center'>{about}</p>
                    </div>



                    {books && 
                    <div className=' flex flex-col items-center justify-center space-y-4 pt-10 rounded-2xl'>
                        <Heading text='Books' />
                      
                        <CardScrollerSection data={books} />

                    </div>
                    }

 

                    <div className='flex flex-col items-center space-y-4 pt-10'>
                        <Heading text='Creations' />
                        {user_id && <PostView user_id={user_id} />}
                    </div>



                <div className='bg-white py-20 flex flex-col items-center space-y-10 rounded-2xl'>

                    <Heading text='Personal Details' />



                    <div className='flex flex-col items-center space-y-1 pt-0'>
                        <SubHeading text='From' />
                        {location.map((loc, index) => (<LocationCard key={index} data={loc} />))}
                    </div>


                    { education &&    
                    <div className='flex flex-col items-center space-y-1 pt-0'>
                        <SubHeading text='Education' />
                        {education.map((edu, index) => (<EducationCard key={index} data={edu} />))}
                    </div>
                    }
                </div>

                </div>
            </div>
        </div>
    );
}





function LinkCard({ data }) {

    return (
        <>
            <Link className='w-full hover:text-orange-600' to={`/book/${data.id}`}>{data.name}</Link>
        </>
    )
}



function EducationCard({ data }) {

    return (
        <div className='space-y-1 border-0'>
            <p className='text-2xl font-semibold text-center'>{data.course} </p>
            <p className='text-center'>{data.institute} </p>
            <p className='text-center'>{data.start} - {data.end} </p>
            {data.grade && <p className='text-center'>Grade: {data.grade} </p>}
        </div>
    )
}



function LocationCard({ data }) {

    return (
        <div className='space-y-2 m-3'>
            <p className='text-center'>{data.village} </p>
            <p className='text-2xl text-center'>{data.city}, {data.state}, {data.country} </p>
            {/* <p className='text-center'>{data.start} - {data.end} </p>
            {data.grade && <p className='text-center'>Grade: {data.grade} </p>} */}

        </div>
    )
}


function Heading(props) {
    return (
        <div className="w-full h-full flex justify-center items-center">
            <h1 className="text-md md:text-2xl lg:text-2xl font-bold text-[#C26A4A]">{props.text}</h1>
        </div>
    );
}

function SubHeading(props) {
    return (
        <div className=" border-0 w-full h-full flex justify-center items-center">
            <h1 className="text-xl md:text-md lg:text-md font-bold text-[#ad8443]">{props.text}</h1>
        </div>
    );
}



function Section({Children}) {
    return (
        <div className='bg-white flex flex-col items-center space-y-4 py-10 rounded-2xl'>
       {Children}
        </div>
        
    );
}






export default WriterView;
