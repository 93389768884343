import React, { useRef } from 'react';
import WriterCard from './WriterCard';

const CardScrollerSection = ({ data }) => {
    const scrollContainerRef = useRef(null);

    const scrollLeft = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({ left: -301, behavior: 'smooth' });
        }
    };

    const scrollRight = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({ left: 301, behavior: 'smooth' });
        }
    };

    return (
        <div className="relative w-full mx-auto flex items-center h-fit bg-zinc-000 py-10 rounded-xl px-5">
            <button className="size-[10vh] mr-5 bg-gray-800 text-white p-2 rounded-full" onClick={scrollLeft}>
                Prev
            </button>
            <div ref={scrollContainerRef} className="flex items-center justify-center overflow-x-scroll h-full scrollbar-hide space-x-2">
                {data.map((item, index) => (
                    <div key={index} className="min-w-[180px] md:min-w-[230px] h-full bg-white shadow-lg rounded-lg p-4 flex items-center justify-center">
                        <WriterCard data={item}/>
                    </div>
                ))}
            </div>
            <button
                className="size-[10vh] ml-5  bg-gray-800 text-white p-2 rounded-full"
                onClick={scrollRight}
            >
                Next
            </button>
        </div>
    );
};

export default CardScrollerSection;
