import React, { useState } from 'react';
import PocketBase from 'pocketbase';
import { IconClose } from './icons';
import GoogleInputComponent from './InputTools';

// Initialize PocketBase client
const pb = new PocketBase(process.env.REACT_APP_PKT_URL);



function PostCreationForm() {

    const [text, setText] = useState('');

    const [category, setCategory] = useState('');

    const [error, setError] = useState(null);

    const [success, setSuccess] = useState(null);
    const [loading, setLoading] = useState(false);

    const writerId = localStorage.getItem('writerId');

    const userId = localStorage.getItem('userId');

    const [open, setOpen] = useState(false);

    const toggleEdit = () => {
        setOpen(!open);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const data = {
            text: text,
            user_id: userId,
            writer_id: writerId,
            category: category,
        };

        try {
            const record = await pb.collection('post').create(data);
            setSuccess('Post created successfully!');
            setText('');
            setCategory('');
            setError(null);
            setLoading(false);
            
        } catch (error) {
            setError('Error creating post. Please try again.');
            setSuccess(null);
            setLoading(false);
        }
    };



    const iconPen = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
    </svg>;



    const loadingIcon = <svg id="Layer_1" height="16" viewBox="0 0 24 24" width="16" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m12.1 19.58a1.746 1.746 0 0 1 -.872-.234l-5.2-3a1.752 1.752 0 0 1 -.64-2.391l.75-1.3a4.4 4.4 0 0 1 3.462-1.831.235.235 0 0 0 .18-.313 4.4 4.4 0 0 1 -.146-3.917l.75-1.3a1.754 1.754 0 0 1 2.39-.641l5.2 3a1.752 1.752 0 0 1 .64 2.391l-.75 1.3a4.4 4.4 0 0 1 -3.464 1.832.233.233 0 0 0 -.18.312 4.4 4.4 0 0 1 .146 3.918l-.75 1.3a1.75 1.75 0 0 1 -1.516.874zm-.2-13.66a.25.25 0 0 0 -.217.125l-.75 1.3a3.092 3.092 0 0 0 .23 2.585 1.737 1.737 0 0 1 -1.377 2.384 3.093 3.093 0 0 0 -2.353 1.093l-.75 1.3a.25.25 0 0 0 .091.342l5.2 3a.252.252 0 0 0 .341-.092l.75-1.3a3.092 3.092 0 0 0 -.23-2.585 1.735 1.735 0 0 1 1.377-2.383 3.1 3.1 0 0 0 2.353-1.094l.75-1.3a.25.25 0 0 0 -.091-.342l-5.2-3a.253.253 0 0 0 -.124-.033z"/><path d="m22 12.75a.75.75 0 0 1 -.75-.75 9.25 9.25 0 0 0 -15.791-6.541 9.427 9.427 0 0 0 -.862.993.75.75 0 0 1 -1.2-.9 11.235 11.235 0 0 1 1-1.15 10.75 10.75 0 0 1 18.353 7.598.75.75 0 0 1 -.75.75z"/><path d="m12 22.75a10.748 10.748 0 0 1 -10.75-10.75.75.75 0 0 1 1.5 0 9.25 9.25 0 0 0 15.791 6.541 9.331 9.331 0 0 0 .861-.992.751.751 0 0 1 1.2.9 11.087 11.087 0 0 1 -1 1.151 10.684 10.684 0 0 1 -7.602 3.15z"/><path d="m20.485 21.235a.75.75 0 0 1 -.75-.75v-2.078h-2.079a.75.75 0 0 1 0-1.5h2.829a.75.75 0 0 1 .75.75v2.828a.75.75 0 0 1 -.75.75z"/><path d="m6.343 7.093h-2.828a.75.75 0 0 1 -.75-.75v-2.828a.75.75 0 0 1 1.5 0v2.078h2.078a.75.75 0 0 1 0 1.5z"/></svg>;



    return (

        <>

            <button className='bg-gray-200 hover:bg-gray-300 px-4 py-3 font-semibold rounded-full w-[60%] h-max flex justify-start items-center space-x-4' onClick={toggleEdit}>
                <div className='bg-white py-2 px-5 rounded-full flex justify-center items-center space-x-2'>{iconPen}</div>
                <p>Write.....</p>
            </button>


            {open &&


                <div className="fixed top-0 left-0 bg-black/90  w-[100vw] h-full flex justify-center items-center backdrop-blur-sm">

                    <div className="w-[50vw] h-auto bg-white p-5 rounded-lg ">

                        {success ?
                            <div className='flex flex-col space-x-5 items-center justify-center'>

                                <p className='p-10 text-5xl font-semibold text-zinc-800'>Posted</p>

                                <div className='flex space-x-3 pb-10'>
                                    <button className='bg-gray-200 hover:bg-gray-300 px-10  font-semibold py-2 rounded-full w-max h-max' onClick={() => (setSuccess(null))}>Write More</button>
                                    <button className='bg-gray-200 hover:bg-gray-300 px-10  font-semibold py-2 rounded-full w-max h-max' onClick={() => (toggleEdit(), setSuccess(null))}>Close</button>
                                </div>
                            </div>


                            : (<> <div className='flex justify-between items-center bg-gray-000 mb-5'>
                                <h2 className="text-xl font-bold">Create a Post</h2>
                                <button onClick={toggleEdit}>{<IconClose ratio="15" />}</button>
                            </div>

                                <form onSubmit={handleSubmit} className='w-full'>

                                    <div className="mb-4">
                                        {/* <label className="block text-sm font-medium text-gray-700">Text</label> */}
                                        <textarea
                                            value={text}
                                            onChange={(e) => setText(e.target.value)}
                                            className="w-full px-3 py-2 border rounded-md"
                                            rows="4"
                                            required
                                        ></textarea>
                                    </div>
                                    <GoogleInputComponent text={text} setText={setText} />

                                    {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
                                    {success && <p className="text-green-500 text-sm mb-4">{success}</p>}

                                    <div className="mb-4 flex justify-between items-center space-x-3 w-full " >
                                        {/* <label htmlFor="category" className="block font-medium text-gray-700">Choose a Category</label> */}
                                        <select
                                            id="category"
                                            name="category"
                                            value={category}
                                            onChange={(e) => setCategory(e.target.value)}
                                            className='p-3 border rounded-full'
                                            required
                                        >
                                            <option value="">Select a category</option>
                                            <option value="qoute">Qoute</option>
                                            <option value="short-story">Short Story</option>
                                            <option value="thought">Thought</option>
                                        </select>


                                        <button type="submit" className="bg-gray-700 hover:bg-gray-800 text-white font-bold py-2 px-4 rounded-full" >
                                            {loading ? "Creating..." : "Create Post"}
                                        </button>
                                    </div>
                                </form></>)}
                    </div>

                </div>
            }
        </>
    );
}

export default PostCreationForm;
