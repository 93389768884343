import { Link } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import { fetchDataWithFilter, fetchDataForFeed } from '../api/api'; // Adjust the path as necessary
import PocketBase from 'pocketbase';
import TimeView from '../Components/TimeView';
import { FaHeart, FaRegHeart, FaRegClock, FaPenNib } from "react-icons/fa";
import MyContext from '../Components/MyContext';

const pb = new PocketBase(process.env.REACT_APP_PKT_URL);



function Content({ data1, Fetchtype = '', filter }) {
    const [records, setRecords] = useState([]);
    const [likes, setLikes] = useState({});

    const [loading, setLoading] = useState(true);


    const {refresh, allContent} = useContext(MyContext);

    useEffect(() => {
        async function getAllWriters() {
            try {
                let data;

                if (Fetchtype.toLowerCase() === 'feed') {
                    data = await fetchDataForFeed('user_id, writer_id, like', data1);
                } else {
                    //data = await fetchDataWithFilter('content', 'user_id, writer_id, like', filter);
                    data = allContent;
                }

                if (data) {
                    setRecords(data);
                    console.log('Fetched all writers:', data);
                    if (data) { setLoading(false); }

                    // Initialize likes
                    const initialLikes = {};
                    data.forEach(post => {
                        initialLikes[post.id] = post.like ? post.like.length : 0;
                    });
                    setLikes(initialLikes);
                }
            } catch (error) {
                console.error('Error fetching writers:', error);
            }
        }
        getAllWriters();
    }, [refresh, allContent]);

    return (
        <div className='w-full h-[100vh] bg-slate-000'>


            {loading ? (<div className='flex justify-center items-center w-full bg-black/80 text-white h-[92vh]'><p>Loading</p></div>) :
                <div className='m-5 columns-0 md:columns-2 lg:columns-3'>
                    {records.map((post, index) => (
                        <ContentCard key={index} data={post} likes={likes} setLikes={setLikes} />
                    ))}
                </div>
            }


        </div>
    );
}
















function ContentCard({ data, likes, setLikes, key }) {
    const userId = localStorage.getItem('userId');
    const userExists = data.expand ? data?.expand?.like?.some(likeObj => likeObj.user_id === userId) : false;

    return (
        <div className=' h-max rounded-xl mb-5 py-5 px-5 font bg-white shrink-0 flex flex-col justify-center items-center space-y-5 overflow-hidden'>

            <p className='text-2xl font-semibold line-clamp-5 '>{data.title}</p>
            <p className='text-md  line-clamp-5 '>{data.text}</p>

            <Link className='bg-zinc-200 hover:bg-orange-300 px-4 py-1 rounded-full' to={`/content/${data.id}`}>Read Full</Link>

            {data.expand && data.expand.writer_id &&
                <Link to={`/${data.expand.writer_id.id}/${data.expand.writer_id.name}`}>
                    <p className='text-xs' >- {data.expand.writer_id.name}</p>
                </Link>
            }




            <div className='w-fit flex items-center p-0 bg-zinc-500 grid gap-x-4 grid-cols-3 mx-auto'>
                
                {data.id && 
                <div className='bg-green-500 w-fit'>
                    <LikeButton
                        post_id={data.id}
                        user_id={userId}
                        like={data.expand && data.expand.like}
                        userExists={userExists}
                        likes={likes}
                        setLikes={setLikes}
                    />
                </div>
                }

                <div className='w-fit bg-red-500 flex justify-center items-center text-sm space-x-2'>
                <FaRegClock/> <TimeView created={data.created} />
                </div>
    
                <div className='w-fit bg-red-200 flex justify-center items-center space-x-2'>
                    <FaPenNib/> <p className='text-sm' > {data.expand && data.expand.writer_id && data.expand.writer_id.name}</p>
                </div>

            </div>


        </div>
    );
}

















function LikeButton({ user_id, post_id, like, likes, setLikes, userExists }) {

    const [liked, setLiked] = useState(userExists);

    const data = {
        user_id: user_id,
        post_id: post_id
    };


    const setLike = async () => {

        try {

            setLiked(true);

            setLikes(prevLikes => ({
                ...prevLikes,
                [post_id]: prevLikes[post_id] + 1
            }));

            const likeRecord = await pb.collection('like').create(data);
            const postRecord = await pb.collection('post').update(post_id, { 'like+': [likeRecord.id] });

        } catch (error) {
            console.error('Error creating like or updating post:', error);
        }
    };


    const setUnlike = async () => {
        try {

            setLiked(false);

            setLikes(prevLikes => ({
                ...prevLikes,
                [post_id]: prevLikes[post_id] - 1
            }));


            const userId = localStorage.getItem('userId');
            const likeRecord = await pb.collection('like').getFirstListItem(`user_id="${userId}" && post_id="${post_id}"`,);
            await pb.collection('like').delete(likeRecord.id);
            const postRecord = await pb.collection('post').update(post_id, { 'like-': [likeRecord.id] });


        } catch (error) {
            console.error('Error creating like or updating post:', error);
        }
    };


    return (

        <>
           <button 
    className={liked ? 'bg-orange-800 text-white py-1 px-3 rounded-full text-md' : 'bg-zinc-200 text-black py-1 px-3 rounded-full text-md'} 
    onClick={liked ? setUnlike : setLike}
>
    {liked ? 
        <div className='flex space-x-3 flex items-center justify-center'>
            <FaHeart /> <p> {likes[post_id]}</p>
        </div> 
        : 
        <div className='flex space-x-3 flex items-center justify-center'>
            <FaRegHeart /> <p> {likes[post_id]}</p>
        </div> 
    }
</button>
        </>
    );
}

export default Content;
