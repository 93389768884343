import { useEffect, useState, useContext } from 'react';
import { Heading } from '../Typography/Typography';
import { fetchDataWithReferences } from '../api/api'; // Adjust the path as necessary
import BookCard from '../Components/BookCard';
import MyContext from "../Components/MyContext";

function Books() {
  
  
  return (
    <div>
      <div className='w-full h-[92vh] bg-slate-100 grid grid-cols-5 '>
        <div className='bg-gray-200 rounded-xl m-3'></div>
        <div className='col-span-4 overflow-scroll'>
          <BooksSection header='New Release' />
          <BooksSection header='Popular Books' />
          {/* <BooksSection header='Books by Eminent Writers' />
          <BooksSection header='Historicals' />
          <BooksSection header='Auto-Biographies' />
          <BooksSection header='Poetry'/>
          <BooksSection header='Novels'/> */}
        </div>
      </div>
    </div>
  );
}




function BooksSection({ header }) {
 
  const {allBooks} =  useContext(MyContext);

  return (
    <div className='flex items-center justify-center '>
      <div className='w-[95vw] md:w-[80vw] h-full bg-white overflow-auto rounded-xl my-3 mx-3'>
        <div className='h-[12vh] bg-slate-000 col-span-3'>
          <Heading text={header} />
        </div>
        <div className='h-full p-8 bg-slate-000 flex overflow-auto space-x-6'>
          {allBooks.map((book, index) => (<BookCard key={index} data={book} />))}
        </div>
      </div>
    </div>
  );
}




function FilterConsole({ header }) {
  const [record, setRecord] = useState([]);

  useEffect(() => {
    async function getData() {
      const data = await fetchDataWithReferences('books', 'writers,publishers');
      if (data) {
        setRecord(data);
        console.log(data);
      }
    }
    getData();
  }, []);

  return (
    <div className='flex items-center justify-center '>
      <div className='w-[95vw] md:w-[80vw] h-full bg-white overflow-auto rounded-xl my-3 mx-3'>
        <div className='h-[12vh] bg-slate-000 col-span-3'>
          <Heading text={header} />
        </div>
        <div className='h-full p-8 bg-slate-000 flex overflow-auto space-x-6'>
          {record.map((book, index) => (<BookCard key={index} data={book} />))}
        </div>
      </div>
    </div>
  );
}

export default Books;
