import React, { useState, useEffect } from 'react';
import PocketBase from 'pocketbase';

const pb = new PocketBase(process.env.REACT_APP_PKT_URL);




const AddTag = ({ tags, setTags }) => {


    const [item, setItem] = useState(tags || []);
    const [data, setData] = useState([]);
    const [newTag, setNewTag] = useState('');

    console.log('Item show:', item);


    const writerId = localStorage.getItem('writerId');

    useEffect(() => {
        async function fetchTags() {
            try {
                const result = await pb.collection('tags').getFullList();
                setData(result);
                console.log('Tags fetched:', result);
            } catch (error) {
                console.error('Error fetching tags:', error);
            }
        }
        fetchTags();
    }, []);

    const handleChange = (e) => {
        const { value } = e.target;
        setItem([...item, value]);
        setTags([...tags, value]);
        console.log('tags', tags);
    };

    const handleAddTag = async () => {

        if (newTag.trim() === '') return;
        try {
            const result = await pb.collection('tags').create({ name: newTag });
            setData([...data, result]);
            setItem([...item, result.id]);
            setTags([...tags, result.id]);
            setNewTag(''); // Clear the input field

        } catch (error) {
            console.error('Error adding new tag:', error);
        }
    };



    const handleDeleteTag = (id) => {
        setItem(item.filter(tagId => tagId !== id));
        setTags(tags.filter(tagId => tagId !== id));
    };



    return (
        <div className="container flex justify-center items-center flex-col mx-auto p-4 space-x-2 space-y-3  border border-zinc-200 rounded-xl">

            <div className='space-x-2 flex items-center'>

                <div className='space-x-2'>
                 
                    <label>Add a Tag</label>

                    <select
                        id="tag"
                        name="tag_id"
                        value={item}
                        onChange={handleChange}
                        className='p-3 border rounded-full text-black'
                        required
                    >
                        <option value="" disabled>Select tag</option>
                        {data.map((tag) => (
                            <option key={tag.id} value={tag.id}>
                                {tag.name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="">
                    <input
                        type="text"
                        value={newTag}
                        onChange={(e) => setNewTag(e.target.value)}
                        placeholder="New tag"
                        className="p-2 border rounded"
                    />
                    <button onClick={() => handleAddTag} className="ml-2 px-4 py-2 bg-zinc-800 text-white rounded">
                        Create Tag
                    </button>

                </div>
            </div>


            <div className='flex space-x-1'>
                {item.map((tagId, index) => (
                    <div key={index} className="flex w-fit p-1 px-3 text-white rounded-full bg-zinc-900">
                        <p>{data.find(tag => tag.id === tagId)?.name}</p>
                        <button onClick={() => handleDeleteTag(tagId)} className="ml-2 text-red-000">
                            x
                        </button>
                    </div>
                ))}
            </div>


        </div>
    );
};









function StatusWindow({ value = false }) {
    const [show, setShow] = useState(value);

    useEffect(() => {
        setShow(value);
        if (value) {
            const timer = setTimeout(() => setShow(false), 3000); // Auto-hide after 3 seconds
            return () => clearTimeout(timer); // Cleanup the timer
        }
    }, [value]);

    return show ? (
        <div className="fixed top-0 left-0 w-full h-[100vh] z-50 flex items-start justify-end p-10">
            <div className="bg-green-500 px-5 py-3 rounded-full text-white">
                <p>Successfully</p>
            </div>
        </div>
    ) : null;
}







export default AddTag;
