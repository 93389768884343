import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PocketBase from 'pocketbase';
import { Link } from 'react-router-dom';
import { fetchDataWithReferences } from '../api/api';
const pb = new PocketBase(process.env.REACT_APP_PKT_URL);

const SignIn = () => {
    const [emailOrUsername, setEmailOrUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const authData = await pb.collection('users').authWithPassword(emailOrUsername, password);
            const record = await pb.collection('users').getOne(pb.authStore.model.id);
            
            const data = fetchDataWithReferences('users', 'writer_id');
            
            console.log('fetch record',record);
            
            console.log('fetch data',data);
            
            // Store auth data in local storage
            localStorage.setItem('authData', JSON.stringify(authData));
            localStorage.setItem('token', pb.authStore.token);
            localStorage.setItem('userId', pb.authStore.model.id); 
            localStorage.setItem('writerId', record.writer_id);
            setSuccess('Sign in successful!');
            setError('');
            
            // Navigate to profile page
            console.log(pb.authStore.isValid);
            console.log(pb.authStore.token);
            console.log(pb.authStore.model.id);

            //window.location.reload();

        } catch (err) {
            setError('Sign in failed. Please check your credentials.');
            setSuccess('');
        }
    };

    return (
<div
  style={{
    backgroundImage: `url(https://likhtum.pockethost.io/api/files/wzjl660phetvxti/4j3ik81ijh7pquu/login_background_x1_BHLM69D7B1.png)`, 
    backgroundSize:'cover'
  }}
  className="min-h-screen grid grid-cols-1 md:grid-cols-2 w-full object-contain flex items-center justify-center bg-zinc-900"
>
            

            <div className="bg-white rounded-xl shadow-md w-full max-w-md">
            </div>


            <div className='flex justify-center items-center'>

            <div className="bg-white p-8 rounded-xl shadow-md w-full max-w-sm border">
               
                <h1 className="text-2xl font-bold mb-4">Log In</h1>
               
                {error && <p className="text-red-500 mb-4">{error}</p>}
                {success && <p className="text-green-500 mb-4">{success}</p>}
               
               
                <form onSubmit={handleSubmit}>
                   
                    <div className="mb-4">
                        <label className="block text-gray-700">Email or Username</label>
                        <input
                            type="text"
                            className="mt-1 p-2 w-full border rounded"
                            value={emailOrUsername}
                            onChange={(e) => setEmailOrUsername(e.target.value)}
                            required
                        />
                    </div>
                   
                    <div className="mb-4">
                        <label className="block text-gray-700">Password</label>
                        <input
                            type="password"
                            className="mt-1 p-2 w-full border rounded"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                   
                    <button type="submit" className="w-full bg-orange-600 text-white p-2 rounded">
                        Sign In
                    </button>
              
                </form>
                
                <div className=' w-full flex justify-center my-5'>
                <p>Dont Have Account ?</p> <Link to={'/signup'} className='text-orange-700 mx-2 font-bold hover:text-orange-800'>Signup Now</Link>
                </div>

            </div>
            </div>

        </div>
    );
};



function GoProfile(){

    const navigate = useNavigate();

    navigate('/profile');

}

export default SignIn;
