import { useEffect, useState, useContext } from 'react';
import { Heading } from '../Typography/Typography';
import { fetchDataWithReferences, fetchDataWithFilter } from '../api/api'; // Adjust the path as necessary
import WriterCard from '../Components/WriterCard';
import MyContext from "../Components/MyContext";



function Writer() {

  const sect = ['emarging', 'eminent'];

  const {allWriters} =  useContext(MyContext);

  return (
    <div>
      <div className='w-full h-[92vh] bg-zinc-900 grid grid-cols-5'>
        {/* <div className='bg-gray-200 rounded-xl m-3'></div> */}
        <div className='col-span-5 overflow-scroll h-[92vh]'>
          <WriterSection header='Eminent' writers={allWriters.filter(writer => writer.type === 'eminent')} />
          <WriterSection header='' writers={allWriters.filter(writer => writer.type === '')} />

        </div>
      </div>
    </div>
  );
}




function WriterSection({ header, writers }) {
  return (
    <div className='flex items-center justify-center '>
      <div className='w-[95vw] md:w-[80vw] h-full bg-white overflow-auto rounded-xl my-3 mx-3'>
        <div className='h-[12vh] bg-slate-000 col-span-3'>
          <Heading text={header} />
        </div>
        <div className='h-full p-8 bg-slate-000 flex overflow-auto space-x-6'>
          {writers.map((writer, index) => (<WriterCard key={index} data={writer} />))}
        </div>
      </div>
    </div>
  );
}


export default Writer;
