import React, { useState, useEffect, useRef } from 'react';
import PocketBase from 'pocketbase';
import googleTransliterate from 'google-input-tool'; // Keep this for transliteration
import DropDownList from './DropDownList';
import AddTag from './AddTag';

// Initialize PocketBase client globally
const pb = new PocketBase(process.env.REACT_APP_PKT_URL);





const CreateText = ({lang = 'pa-t-i0-und', setOutputText}) => {


    const [upload, setUpload] = useState(['Upload Blog', 'black']);
    const [data, setData] = useState({ Title: '', Subtitle: '' });
    const [bookId, setBookId] = useState('');
    const [tags, setTags] = useState([]);
    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [bg, setBg] = useState('bg-zinc-100');
    const [textCol, setTextCol] = useState('text-black');
    const [language, setLanguage] = useState(lang);
    const textAreaRef = useRef(null); // Reference for the textarea



    const handleTransliteration = async (lastWord) => {
        const inputLanguage = language;
        const maxResult = 1;
        const request = new XMLHttpRequest();

        try {
            const response = await googleTransliterate(request, lastWord, inputLanguage, maxResult);
            return response[0][0] || lastWord; // Return the transliterated word
        } catch (error) {
            console.error('Error transliterating text:', error);
            return lastWord; // Fallback to the original word
        }
    };

    useEffect(() => {
        const handleKeyDown = async (event) => {
            if (event.key === ' ') { // Space key
                const fullText = textAreaRef.current.value.trim();
                const words = fullText.split(/\s+/);
                const lastWord = words[words.length - 1];

                const transliteratedWord = await handleTransliteration(lastWord);
                words[words.length - 1] = transliteratedWord;
                const updatedText = words.join(' ') + ' ';

                // Update the textarea with the new text
                setText(updatedText);
                setOutputText(updatedText);

                textAreaRef.current.value = updatedText; // Set the textarea value
                textAreaRef.current.focus(); // Keep the focus
            }
        };

        const textarea = textAreaRef.current;
        if (textarea) {
            textarea.addEventListener('keydown', handleKeyDown);
            return () => {
                textarea.removeEventListener('keydown', handleKeyDown);
            };
        }
    }, [text]);

    // const handleSubmit = async (e) => {
    //     e.preventDefault();

    //     const userId = localStorage.getItem('userId');
    //     const writerId = localStorage.getItem('writerId');

    //     const data = {
    //         title: title,
    //         text: text,
    //         book_id: bookId,
    //         tags: tags,
    //         writer_id: writerId,
    //         user_id: userId,
    //     };

    //     try {
    //         const record = await pb.collection('content').create(data);
    //         setTitle('');
    //         setText('');
    //         setUpload(['Content Created', 'Green']);
    //     } catch (error) {
    //         console.error('Error creating post:', error);
    //     }
    // };

    // const writerId = localStorage.getItem('writerId');

    // const toggleTheme = () => {
    //     setBg(prevBg => (prevBg === 'bg-zinc-100' ? 'bg-zinc-400' : 'bg-zinc-100'));
    //     setTextCol(prevTextCol => (prevTextCol === 'text-black' ? 'text-white' : 'text-black'));
    // };

    return (
        <div className="absolute bottom-0 overflow-scroll left-0 py-20 container w-full h-[100vh] flex flex-wrap items-center justify-center">
            <div className={`min-h-[130vh] w-[50vw] ${bg} ${textCol} flex flex-col items-center p-5 space-y-0 text-lg rounded-xl`}>

                <div className='bg-slate-200 min-h-[50vh] border-0 w-full rounded-xl'>
                    <textarea
                        ref={textAreaRef}
                        className={`min-h-[50vh] w-full bg-slate-000`}
                        placeholder='Compose an epic...'
                        onChange={(e) => setText(e.target.value)}
                        value={text}
                    />
                </div>

                <div className='w-full h-full bg-gray-000 flex flex-col items-center justify-center'>
                    {/* <DropDownList categoryId={bookId} setCategotyId={setBookId} collectionName='books' filter={`writer_id="${writerId}"`} /> */}
                    {/* <AddTag tags={tags} setTags={setTags} /> */}
                    {/* <button onClick={handleSubmit} className={`my-2 py-3 px-9 text-white rounded-full duration-200 ${upload[1] === 'green-700' ? 'bg-green-700' : 'bg-black'}`}> */}
                        {/* {upload[0]} */}
                    {/* </button> */}
                </div>

            </div>
        </div>
    );
};

export default CreateText;
