import { useEffect, useState } from 'react';
import { Heading, SubHeading, WriterNameMd, TextMd, TextClip, Buttonprimary } from '../Typography/Typography';
import { fetchData, fetchDataWithReferences } from '../api/api'; // Adjust the path as necessary
import { Link, useNavigate } from 'react-router-dom';
import PocketBase from 'pocketbase';
import LogoutButton from './LogoutButton';
import Notification from './Notification';
import ImageView from './ImageView';
import { FiMenu } from "react-icons/fi";
import { PiUserCircleLight } from "react-icons/pi";
import { FaBars, FaBarsStaggered, FaRegCircleUser} from "react-icons/fa6";
import LanguageSetting from './LanguageSetting';

const pb = new PocketBase(process.env.REACT_APP_PKT_URL);

function Navigation() {


  const isAuthenticated = !!localStorage.getItem('token');
  const logo = process.env.PUBLIC_URL + "./likhtum-logo-white.svg";

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };



  const [onHover, setOnHover] = useState(false);

  const handleMouseEnter = () => {
    setOnHover(true);
  };

  const handleMouseLeave = () => {
    setOnHover(false);
  };





  const desktop = <div className={`w-full fixed h-[8%] ${onHover ? 'bg-black' : 'bg-zinc-800'} duration-300 grid grid-cols-8 text-white shadow-xl flex justify-center`}>


    <div className='flex ml-5 space-x-10 border-0 h-full items-center col-span-2'>
      <Link to={'/'}>
        <img className='h-[4vh]' src={logo} />
      </Link>
    </div>

    <div className=' flex space-x-0 col-span-4 border-0 h-full justify-center items-center' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <HoverButton link='/' name='Home' content={['a', 'b', 'c']} />
      <HoverButton link='/writers' name='Writers' content={['a', 'b', 'c']} />
      <HoverButton link='/books' name='Books' content={['a', 'b', 'c']} />
      <HoverButton link='/read/posts' name='Read' content={['Quotes', 'Books', 'Articles']} />
      <HoverButton link='/publishers' name='Publishers' content={['New Publishers', 'Top Publishers', 'Oldest Publishers']} />

      {onHover && (
        <div className="absolute top-[8vh] left-0 w-full h-[40vh] bg-black/90 backdrop-blur animate-fade animate-duration-150 ">

        </div>
      )}


    </div>


    <div className='relative col-span-2 mr-3 flex space-x-3 justify-end items-center'>

      {isAuthenticated && <Notification />}


      <div className='flex size-10 justify-center text-4xl font-thin items-center rounded-full border-0'>
        {isAuthenticated ? <Link to='/profile'><PiUserCircleLight /> </Link> : <HoverLinkButton link='/login' name='Login' />}
      </div>

      <button className='border-0 p-2 px-5 rounded-full text-white' onClick={toggleOpen}>
        {isAuthenticated ? (open ? <FaBarsStaggered/> : <FaBars /> ) : <Link to={'/login'}>Login</Link>}
      </button>


      {open && isAuthenticated &&

        <>

          <div onClick={toggleOpen} className='bg-zinc-800 space-y-3 border p-3 px-5 text-white rounded-md absolute flex flex-col justify-center items-center mx-auto w-[11vw] h-max top-[9vh] right-[3vw]'>
            {isAuthenticated ? <HoverLinkButton link='/collection/posts' name='Collection' /> : <HoverLinkButton link='/login' name='Login' />}
            {isAuthenticated ? <LogoutButton /> : <HoverLinkButton link='/login' name='Login' />}
            <LanguageSetting/>
          </div>

        </>
      }

    </div>

  </div>;













  const mobile = <div className='w-full fixed h-[8%] bg-zinc-800 text-white shadow-xl flex justify-between'>

    <div className='flex space-x-10 border-0 h-full justify-center items-center'>
      <img className='h-[4.5vh]' src={logo} />
    </div>

    <div className='relative flex space-x-10 border-0 h-full justify-center items-center'>

      {/* <button className='border p-2 px-5 rounded-full text-white' onClick={toggleOpen}>{isAuthenticated ? 'menu' : <Link to={'/login'}>Login</Link>}</button> */}
      <button className='border p-2 px-5 rounded-full text-white' onClick={toggleOpen}>Explore</button>

      {open &&
        <div onClick={toggleOpen} className='bg-zinc-800 space-y-3 border p-3 px-5 text-white rounded-md absolute flex flex-col justify-center items-center mx-auto w-[100vw] h-max top-[9vh] right-0'>

          <HoverLinkButton link='/' name='Home' />
          <HoverLinkButton link='/writers' name='Writers' />
          <HoverLinkButton link='/books' name='Books' />
          <HoverLinkButton link='/read/posts' name='Read' />
          <HoverLinkButton link='/publishers' name='Publishers' />

          {isAuthenticated ? <HoverLinkButton link='/profile' name='Profile' /> : null}
          {isAuthenticated ? <HoverLinkButton link='/collection/posts' name='Collection' /> : null}
          {isAuthenticated ? <LogoutButton /> : <HoverLinkButton link='/login' name='Login' />}

        </div>
      }

    </div>


  </div>;


  return window.innerWidth <= 426 ? mobile : desktop;


}






function HoverLinkButton({ link, name }) {


  const [onHover, setOnHover] = useState(false);

  const handleHover = () => {
    setOnHover(true);
  };


  return (
    <div className='w-max text-xl flex justify-center items-center hover:text-orange-400 duration-150'>
      <Link to={link}>{name}</Link>
    </div>
  );
}


function Logout() {

  pb.authStore.clear();
}









function HoverButton({ link, name, content }) {
  const [onHover, setOnHover] = useState(false);

  const handleMouseEnter = () => {
    setOnHover(true);
  };

  const handleMouseLeave = () => {
    setOnHover(false);
  };

  return (
    <div className=" h-full w-full bg-red-000 flex justify-center items-center" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>

      <div className={` w-max text-xl flex justify-center items-center hover:text-orange-400 duration-150`}>
        <Link to={link}>{name}</Link>
      </div>

      {onHover && (
        <div className="absolute top-[8vh] left-0 px-20 w-screen h-[40vh] z-50 space-y-5 p-20">

          {content.map((item, index) => <p key={index} className='animate-fade-left animate-duration-500 text-2xl font-thin' >{item}</p>)}

        </div>
      )}


    </div>
  );
}











export default Navigation;