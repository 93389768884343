import React, { useEffect, useState } from 'react';
import PocketBase from 'pocketbase';
import TimeView from './TimeView';
import { Navigate } from 'react-router-dom';
import { FaRegBell } from "react-icons/fa";


const pb = new PocketBase(process.env.REACT_APP_PKT_URL);






const Notification = () => {

    const [open, setOpen] = useState(false);
    const [iconColor, setIconColor] = useState(false);
    const [notifications, setNotifications] = useState([]);

    const writerId = localStorage.getItem('writerId');
    const userId = localStorage.getItem('userId');
    




    
    useEffect(() => {
       
       
        const fetchNotifications = async () => {
            try {
                const records = await pb.collection('notification')
                    .getFullList({
                        filter: `to="${writerId}" || toUser_id="${userId}" `,
                        sort: '-created'
                    });
                setNotifications(records);
            } catch (error) {
                console.error("Error fetching notifications:", error.message);
            }
        };


        fetchNotifications();


        // Subscribe to real-time updates
        const unsubscribe = pb.collection('notification').subscribe('*', (e) => {
            if (e.action === 'create' && e.record.to === writerId) {
                setNotifications((prevNotifications) => [e.record, ...prevNotifications]);
                setIconColor(true);
            }
        });

        return () => { pb.collection('notification').unsubscribe('*');};
    
    }, [writerId]);



    const toggleOpen = () => {
        setOpen(!open);
    };



    const setRead = async (id) => {
        try {
            await pb.collection('notification').update(id, { read: true });
            setNotifications((prevNotifications) =>
                prevNotifications.map((notification) =>
                    notification.id === id ? { ...notification, read: true } : notification
                )
            );
        } catch (error) {
            console.error("Error updating notification:", error.message);
        }
    };






    return (
        <div className='relative flex space-x-10 border-0 h-full justify-center items-center'>
            <button className='border-0 p-2 px-5 flex justify-center items-center space-x-2 rounded-full text-white'  onClick={()=>{toggleOpen(); setIconColor(false);}}>
            <FaRegBell className={iconColor && 'text-green-500'} /> 
            <p>Notification</p>
            </button>

            {open && (
                <div
                    onClick={toggleOpen}
                    className='bg-zinc-800 space-y-3 border p-3 px-5 text-white rounded-md absolute flex flex-col items-center mx-auto w-[20vw] h-[50vh] overflow-scroll top-[9vh] right-[3vw]'
                >
                    <ul>
                        {notifications.map((notification) => (
                            
                            
                            <li
                                onClick={() => {setRead(notification.id)} }
                                className='mb-5 cursor-pointer'
                                key={notification.id}
                                
                            >
                                <p className={notification.read ? 'text-gray-300' : 'text-green-400'}>
                                    {notification.name}, {notification.text}
                                
                                <TimeView created={notification.created} />
                                </p>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default Notification;
