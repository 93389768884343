import React, { useState, useEffect } from 'react';
import PocketBase from 'pocketbase';

const pb = new PocketBase(process.env.REACT_APP_PKT_URL);




const ImageUpload = ({ collection, id, imageField }) => {
   
   
    const [image, setImage] = useState(null);
    const [preview, setPreview] = useState(null);
    const [status, setStatus] = useState(null);
    const [uploading, setUploading] = useState(false);


    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const record = await pb.collection(collection).getOne(id);
                if (record[imageField]) {
                    setPreview(`${process.env.REACT_APP_PKT_URL}/api/files/${collection}/${id}/${record[imageField]}`);
                }
            } catch (error) {
                console.error('Error fetching profile:', error);
            }
        };

        fetchProfile();
    }, [id, collection, imageField, status]);



    const handleChange = (e) => {
        const file = e.target.files[0];
        setImage(file);

        const previewUrl = URL.createObjectURL(file);
        setPreview(previewUrl);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setUploading(true);
        try {
            const formData = new FormData();
            formData.append(imageField, image);
            const updatedRecord = await pb.collection(collection).update(id, formData);
            setStatus('Upload successful');
            setUploading(false);

            // Fetch updated record to update preview
            // const updatedRecord = await pb.collection(collection).getOne(id);
            setPreview(`${process.env.REACT_APP_PKT_URL}/api/files/${collection}/${id}/${updatedRecord[imageField]}`);

            // Revoke the object URL to free up memory
            URL.revokeObjectURL(preview);
            
        } catch (error) {
            console.error('Error updating profile:', error);
            setStatus('Upload failed');
            setUploading(false);

        }
    };

    return (
        <div className="p-10 bg-slate-000">
            <form onSubmit={handleSubmit} className="space-y-4 bg-pink-000 flex flex-col justify-center items-center p-10">
                <div className="text-sm font-medium w-[20vh] h-[20vh] rounded-full bg-gray-200 flex items-center justify-center">
                    <label htmlFor='file' className="group/item cursor-pointer flex items-center justify-center w-full h-full">
                    <span class="group-hover/item:bg-black/50 size-[20vh] rounded-full flex justify-center items-center text-white duration-150 group-hover/item:visible invisible absolute">Change Image</span>
                        <img 
                            src={preview || 'default-image-url'} 
                            alt={preview || 'no Image'} 
                            className="rounded-full object-cover w-full h-full flex justify-center items-center" 
                        />
                    </label>
                    <input
                        id='file'
                        type="file"
                        name={imageField}
                        onChange={handleChange}
                        className="hidden"
                    />
                </div>
                {image && (
                    <button type="submit" className={`${status && 'bg-green-600'} bg-zinc-700  text-white p-2 rounded-full px-3 mt-4`}>
                    
                    {uploading ?  'Uploading' : (status || 'Upload')}
                    
                    </button>
                )}
            </form>
        </div>
    );
};

export default ImageUpload;
