import React, { useState, useRef, useEffect, useContext } from 'react';
import PocketBase from 'pocketbase';

// @ts-ignore
import googleTransliterate from 'google-input-tool';
import MyContext from './MyContext';

// Initialize PocketBase client globally
const pb = new PocketBase(process.env.REACT_APP_PKT_URL);




const InputTools2 = ({name, text, setText}) => {

    // const [text, setText] = useState('');
    //const [language, setLanguage] = useState('pa-t-i0-und');
    const [upload, setUpload] = useState(false);

    const {language, setLanguage} =  useContext(MyContext);



    useEffect(() => {
        const handleKeyDown = async (event) => {
            if (event.keyCode === 32) { // Space key
                
                const fullText = text.trim();
                const words = fullText.split(/\s+/);
                const lastWord = words[words.length - 1];

                const inputLanguage = language; // Language code for Punjabi transliteration
                const maxResult = 1;
                const request = new XMLHttpRequest();

                try {
                    const response = await googleTransliterate(request, lastWord, inputLanguage, maxResult);
                    const transliteratedWord = response[0][0] || lastWord;

                    // Replace the last word with the transliterated word
                    words[words.length - 1] = transliteratedWord;
                    const updatedText = words.join(' ') + ' ';

                    setText(updatedText);

                } catch (error) {
                    console.error('Error transliterating text:', error);
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
        
    }, [language, text]);


    // const handleSubmit = async (e) => {
    //     e.preventDefault();

    //     const userId = localStorage.getItem('userId');
    //     const writerId = localStorage.getItem('writerId');

    //     const data = {
    //         text: text,
    //         writer_id: writerId,
    //         user_id: userId,
    //     };

    //     try {
    //         await pb.collection('content').create(data);
    //         setText('');
    //         setUpload(['Content Created', 'green']);

    //     } catch (error) {
    //         console.error('Error creating post. Please try again.', error);
    //         setUpload(['Error creating content', 'red']);
    //     }
    // };

    
    return (
        <div className="container w-full">

            <div className='text-2xl w-full h-[50%] border'>
                        <textarea
                            required
                            name={name}
                            className='w-full h-full p-1 rounded-md'
                            placeholder='text'
                            onChange={(e) => setText(e.target.value)}
                            value={text}
                        />
                    </div>
                
        </div>
    );
};

export default InputTools2;
