import React, { useEffect, useState, useContext } from 'react';
import PocketBase from 'pocketbase';
import MyContext from './MyContext';

const pb = new PocketBase(process.env.REACT_APP_PKT_URL);

function LanguageSetting() {
    const { language, setLanguage } = useContext(MyContext);
    const [lngfocus, setLngfocus] = useState({ pa: false, hi: false, ur: false, english: false });

    useEffect(() => {
        setLngfocus({
            pa: language === 'pa-t-i0-und',
            hi: language === 'hi-t-i0-und',
            ur: language === 'ur-t-i0-und',
            en: language === 'en-t-i0-und',
        });
    }, [language]);

    const languages = [
        { code: 'pa-t-i0-und', label: 'ਪ', focusKey: 'pa' },
        { code: 'ur-t-i0-und', label: 'و', focusKey: 'ur' },
        { code: 'hi-t-i0-und', label: 'ह', focusKey: 'hi' },
        { code: 'en-t-i0-und', label: 'E', focusKey: 'en' },

        // Add more languages as needed
    ];

    return (
        <div className='space-y-3 border-t flex flex-col justify-evenly items-center my-10'>
            <div>
                <p>Set Language</p>
            </div>
            <div className='flex space-x-1'>
                {languages.map(lang => (
                    <button
                        key={lang.code}
                        className={`${lngfocus[lang.focusKey] ? 'bg-red-600' : 'bg-zinc-600'} hover:bg-zinc-700 text-white px-3 py-1 rounded-full`}
                        onClick={() => setLanguage(lang.code)}
                    >
                        {lang.label}
                    </button>
                ))}
            </div>
        </div>
    );
}

export default LanguageSetting;
