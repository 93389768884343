import { useEffect, useState } from 'react';
import { Heading, SubHeading, WriterNameMd, TextMd, TextClip, Buttonprimary } from '../Typography/Typography';
import { fetchData, fetchDataWithReferences, fetchDataWithFilter } from '../api/api'; // Adjust the path as necessary
import BookCard from '../Components/BookCard';
import WriterCard from '../Components/WriterCard';
import Posts from './Posts';
import CardScrollerSection from '../Components/CardScrollerSection';

function Homepage() {



  const [records, setRecords] = useState([]);

  useEffect(() => {
    async function getAllWriters() {
      try {
        const data = await fetchDataWithFilter('writer', 'user_id, writer_id, like',);
        if (data) {
          setRecords(data);
          console.log('Fetched all writers:', data);
        }
      } catch (error) {
        console.error('Error fetching writers:', error);
      }
    }
    getAllWriters();
  }, []);


  return (
    <div className='w-full h-[92vh] overflow-scroll'>
      <div className='w-full h-[90vh] bg-slate-300'>
      </div>
      <div className='w-full h-max bg-red-000'>

        {/* <SelectedPoetry /> */}
        {/* <Poetry /> */}
        <FeaturedWriter />
        {/* <PostSection /> */}
        <CardScrollerSection data={records} />
        {/* <WriterIntro /> */}
        {/* <BooksSection /> */}

      </div>
    </div>
  );
}





function SelectedPoetry() {
  return (
    <div className='flex items-center justify-center py-10 '>

      <div className='w-[95vw] md:w-[65vw] h-full bg-red-000 grid grid-cols-3 overflow-hidden rounded-3xl shadow-xl'>

        <div className='h-[12vh] bg-green-000 col-span-3'>
          <Heading text='Selected Works' />
        </div>

        <div className='h-full space-y-5 py-5 mx-20 bg-green-000 col-span-3 flex flex-col justify-center items-center'>
          <TextMd text='Selected Workslected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected Works' />
          <WriterNameMd text='Bubu Batalvi' />
        </div>

        <div className='h-full pb-10 border-b-2 bg-green-000 col-span-3 flex flex-col justify-center items-center'>
          <Buttonprimary name='see full Article' />
        </div>

        <div className='h-[12vh] bg-green-000 col-span-3'>
          <SubHeading text='More article from the Writer' />
        </div>


        <div className='h-auto bg-green-000 mx-3 my-2'>
          <TextClip text='Selected Workslected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected Works' />
        </div>

        <div className='h-auto bg-green-000 mx-3 my-2 border-x'>
          <TextClip text='Selected Workslected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected Works' />
        </div>

        <div className='h-auto bg-green-000 mx-3 my-2'>
          <TextClip text='Selected Workslected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected WorksSelected Works' />
        </div>

        <div className='h-[10vh] bg-green-000 col-span-3'>
          <Buttonprimary name='see all work' />
        </div>


      </div>

    </div>
  );
}


function Poetry() {
  return (
    <div className='flex items-center justify-center my-20'>

      <div className='w-[100vw] md:w-[100vw] h-full bg-red-500 grid grid-cols-4'>



        <div className='h-[12vh] bg-blue-600 col-span-4'>
          <Heading text='Eminent Writers' />

        </div>


        <div className='h-[50vh] bg-blue-400'></div>

        <div className='h-[50vh] bg-blue-500'></div>
        <div className='h-[50vh] bg-blue-400'></div>
        <div className='h-[50vh] bg-blue-500'></div>
        <div className='h-[50vh] bg-blue-500'></div>
        <div className='h-[50vh] bg-blue-400'></div>
        <div className='h-[50vh] bg-blue-500'></div>
        <div className='h-[50vh] bg-blue-400'></div>

        <div className='h-[10vh] bg-blue-800 col-span-4'>
          <Buttonprimary name='Go To All Writers' />

        </div>


      </div>

    </div>
  );
}








function FeaturedWriter() {

  const [writers, setWriters] = useState([]);

  useEffect(() => {
    async function getAllWriters() {
      const data = await fetchDataWithReferences('writer', 'books, location');
      if (data) {
        setWriters(data);
        console.log('Fetched all writers:', data);
      }
    }
    getAllWriters();
  }, []);


  return (
    <div className='w-[100vw] flex items-center justify-center flex-col'>
      <div className='h-[12vh] w-[100vw] bg-blue-000'>
        <Heading text='Featured Writers' />
      </div>

      <div className='w-[100vw] overflow-scroll md:w-[100vw] h-auto py-5 bg-red-000 flex'>
        {writers.map((writer, index) => (
          <WriterCard
            key={index}
            data={writer}
          />
        ))}
      </div>

      <div className='h-auto py-5 w-[80vw] bg-blue-000'>
        <Buttonprimary name='See All' link='/writers' />
      </div>
    </div>
  );
}




function WriterIntro() {

  return (
    <div className='flex items-center justify-center my-10'>

      <div className='w-[95vw] md:w-[80vw] h-full bg-red-000 grid grid-cols-3 grid-rows-1'>

        <div className='py-5 bg-orange-000 col-span-3'>
          <Heading text='Introduction with writer' />
        </div>

        <div className='h-[80vh] border rounded-[50px] overflow-hidden bg-blue-000 col-span-3 grid grid-cols-2 shadow-2xl'>


          <div className=' bg-white flex  col-span-2 md:col-span-1 '>

            <div className=' w-[50%] bg-slate-000 flex justify-center items-center'>
              <div className='aspect-square h-[80%] rounded-full bg-red-200'></div>
            </div>

            <div className=' w-[50%] bg-slate-000 flex flex-col pl-5 items-start justify-center'>
              <p className='font-bold text-xl'>Surjit Patar</p>
              <p className='font-semibold text-md'>Writer poet</p>
              <p className='font-semibold text-md'>Ludhiana, Punjab, India</p>
            </div>

          </div>


          <div className='border-l-2 bg-green-000 col-span-2 md:col-span-1 flex flex-col pl-5 items-start justify-center'>
            <p className='font-semibold text-md'>nbbjdfbcvjhdfb nbbjdfbcvjhdfb nbbjdfbcvjhdfb nbbjdfbcvjhdfb vnbbjdfbc vjhdfbn bbjdfbcvjh dfbnbbjdfb cvjhdfbnbb jdfbcvjhdfb nbbjdfbcvj hdfbnbbjdfb cvjhdfb</p>
          </div>


          <div className='border-t-2 bg-red-000 col-span-2 flex flex-col items-center'>
            <p className='font-bold text-xl py-5 text-[#C5A478]'>From the article of Writer</p>
            <div className='mx-10 h-full col-span-2 flex justify-center items-center flex-wrap'>

              <TextMd text='ddjbcbvd sjcbdjcb vjdsvbcbjdsvcjdwvc hgwdvcc hvwdhgcv whgcvhwvxhgwvxs djhcvdhjcvs dhggcvsdhgcv hgdsgvch gdsvchgdsv chgdsvchg dsvchgds cvhgsdcvhgschgvsghcvsdhgcvshdgcv' />
            </div>
            <Buttonprimary name='see more' link='/writers' />
          </div>


        </div>


      </div>

    </div>
  );
}








function BooksSection() {

  const [record, setRecord] = useState([]);

  useEffect(() => {
    async function getData() {
      const data = await fetchDataWithReferences('books', 'writer_id, publisher_id, book_image');
      if (data) {
        setRecord(data);
        console.log(data);
      }
    }
    getData();
  }, []);


  return (
    <div className='flex items-center w-full justify-center my-20'>
      <div className='w-[95vw] md:w-[80vw] h-full bg-red-000'>
        <div className='h-[12vh] bg-slate-000 col-span-3'>
          <Heading text='New Release' />
        </div>
        <div className='h-full w-full p-8 overflow-x-scroll bg-slate-000 flex space-x-6'>

          {/* {record.map((book, index) => (
          
            <BookCard key={index} data={book} />
          
          ))} */}

        </div>
        <Buttonprimary name='See more' link='/books' />
      </div>
    </div>
  );
}






function PostSection() {
  return (
    <div className='items-center justify-center w-full h-full'>
      <div className='w-[100vw] md:w-[100vw] h-full bg-red-500'>
        <div className='h-[12vh] bg-blue-600 col-span-4'>
          <Heading text='Quotes' />
        </div>
        <div className='h-full bg-zinc-800'>
          <Posts columnStyle='columns-0 md:columns-2 lg:columns-6' />
        </div>
        <div className='h-[10vh] bg-blue-800 col-span-4'>
          <Buttonprimary name='See more quotes' />
        </div>
      </div>
    </div>
  );
}








export default Homepage;
