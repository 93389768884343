import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { fetchDataSingle } from '../api/api'; // Adjust the path as necessary
import ImageView from '../Components/ImageView';

import BookCard from '../Components/BookCard';
import PocketBase from 'pocketbase';
import PostView from '../Components/PostView';
import { Heading, SubHeading, WriterNameMd, TextMd, TextClip, Buttonprimary } from '../Typography/Typography';
import { IconClose } from '../Components/icons';
import WriterCard from '../Components/WriterCard';


const pb = new PocketBase(process.env.REACT_APP_PKT_URL);






function PublisherView() {

    const [record, setRecord] = useState({});
    const [books, setBooks] = useState([]);
    const [location, setLocation] = useState([]);
    const [writer, setWriter] = useState([]);
    const { publisherId } = useParams();




    const [open, setOpen] = useState({ about: false, founders: false, contact: false });

    const toggleEdit = (key) => { setOpen((prev) => ({ ...prev, [key]: !prev[key] })); };




    useEffect(() => {
        async function getData() {
            try {
                const data = await fetchDataSingle('publisher', publisherId, 'books, writer_id, books.writer_id, books.publisher_id, location, user_id, writer_id');
                console.log(data);
                if (data) {
                    setRecord(data);
                    data.expand.books && setBooks(data.expand.books);
                    data.expand.location && setLocation(data.expand.location);
                    data.expand.writer_id && setWriter(data.expand.writer_id);
                    console.log(data);
                    console.log('eee', data.expand);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        getData();
    }, [publisherId]);



    const {
        name,
        age,
        user_id,
        about,
        gender,
        profile_image,
        type,
        expand,
        collectionId,
        id,
    } = record;

    return (
        <div className='w-full h-[92vh] bg-zinc-700 overflow-hidden'>


            <div className='bg-gray-000 w-full h-full flex justify-center overflow-scroll rounded-xl'>

                <div className='bg-white w-[90%] my-10 h-max  p-10 font-serif  space-y-5 rounded-3xl'>

                    <div className='items-center flex flex-col space-y-4 text-center h-[50vh]'>

                        <ImageView collectionId={collectionId} id={id} image={profile_image} type='profile' />
                        <p className='text-5xl font-semibold'>{name}</p>

                    </div>


                    <div className='items-center justify-center flex space-x-10 text-center h-[10vh] border-y'>

                        <button onClick={() => toggleEdit('about')} className='text-2xl font-semibold'>About</button>
                        <button onClick={() => toggleEdit('founders')} className='text-2xl font-semibold'>Founders</button>
                        <button onClick={() => toggleEdit('contact')} className='text-2xl font-semibold'>Contact us</button>

                    </div>




                    {/* <div className='flex flex-col items-center space-y-4 pt-10'>
                        <Heading text='About' />

                        <p className='mx-20 text-center'>{about}</p>
                        {location && <p className='mx-20 text-center'>{location.city} </p>}
                    </div> */}



                    {books &&
                        <div className='flex flex-col items-center'>
                            <Heading text='Books' />
                            <div className='h-full bg-slate-000 grid grid-cols-4 gap-10'>
                                {books.map((book, index) => (<BookCard key={index} data={book} />))}
                            </div>
                        </div>
                    }



                    {/* <div className='flex flex-col items-center space-y-4 pt-10'>
                        <Heading text='Creations' />
                        {user_id && <PostView user_id={user_id} />}
                    </div> */}


                    {/* <Heading text='Personal Details' /> */}




                    {open.about &&
                        <div className='w-full flex flex-col justify-center items-center h-[100vh] fixed top-0 left-0 bg-black/90 backdrop-blur-xl'>
                            <button onClick={() => toggleEdit('about')}>
                                {<IconClose ratio='15' />}
                            </button>

                            {/* <SubHeading text='Founders' /> */}

                            <div className='flex justify-center items-center space-y-2 bg-white w-[60vw] h-[60vh] rounded-xl'>
                                <p className='text-xl mx-10'>{about}</p>
                            </div>
                        </div>
                    }




                    {open.founders &&
                        <div className='w-full flex flex-col justify-center items-center h-[100vh] absolute top-0 left-0 bg-black/90 backdrop-blur-xl'>
                            <button onClick={() => toggleEdit('founders')}>
                                {<IconClose ratio='15' />}
                            </button>

                            {/* <SubHeading text='Founders' /> */}

                            <div className='flex items-center space-y-2 bg-white w-fit h-[60vh] rounded-xl'>
                                {writer.map((item, index) => (<WriterCard key={index} data={item} />))}
                            </div>
                        </div>
                    }



                    {open.contact &&
                        <div className='w-full flex justify-center items-center h-[100vh] absolute top-0 left-0 bg-black/90 backdrop-blur-xl'>
                            <button onClick={() => toggleEdit('contact')}>
                                {<IconClose ratio='15' />}
                            </button>

                            <div className='flex flex-col items-center space-y-2 bg-white w-[50vw] h-[60vh] rounded-xl'>
                                <SubHeading text='Address' />
                                {location.map((loc, index) => (<LocationCard key={index} data={loc} />))}
                            </div>
                        </div>
                    }

                </div>
            </div>
        </div>
    );
}

























function LinkCard({ data }) {

    return (
        <>
            <Link className='w-full hover:text-orange-600' to={`/book/${data.id}`}>{data.name}</Link>
        </>
    )
}



function FounderCard({ data }) {

    return (
        <div className='space-y-2 m-3 w-fit fle flex-col justify-center'>
            <ImageView collectionId={data.collectionId} id={data.id} image='profile_image' type='' />
            <p className='text-2xl text-center'>{data.name} </p>
            <p className='text-center'>{data.about} </p>
        </div>
    )
}



function LocationCard({ data }) {

    return (

        <div className='space-y-2 m-3'>
            <p className='text-center'>{data.village} </p>
            <p className='text-2xl text-center'>{data.city}, {data.state}, {data.country} </p>
            {/* <p className='text-center'>{data.start} - {data.end} </p>
            {data.grade && <p className='text-center'>Grade: {data.grade} </p>} */}

        </div>
    )
}


export default PublisherView;
