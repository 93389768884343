import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { fetchDataWithReferences, fetchSingleData,fetchDataWithFilter } from '../api/api'; // Adjust the path as necessary
import PocketBase from 'pocketbase';
import LikeView from '../api/LikeView';

import { FaHeart, FaRegHeart } from "react-icons/fa";

const pb = new PocketBase(process.env.REACT_APP_PKT_URL);




function PostView({user_id}) {

    const [records, setRecords] = useState([]);
    const [likes, setLikes] = useState({});
    console.log('user id in post',user_id);

    useEffect(() => {
        async function getAllWriters() {
            try {
                const data = await fetchDataWithFilter('post', 'user_id, writer_id, like', `user_id = "${user_id && user_id}"`);
                if (data) {
                    setRecords(data);
                    console.log('Fetched all writers:', data);

                    const initialLikes = {};

                    data.forEach(post => {
                        initialLikes[post.id] = post.like ? post.like.length : 0;
                    });
                    setLikes(initialLikes);
                }
            } catch (error) {
                console.error('Error fetching writers:', error);
            }
        }
        getAllWriters();
    }, []);

    return (
        <div>
            <div className='w-full bg-slate-000 '>
                {/* <div className='bg-gray-300 rounded-xl m-3'></div> */}
                <div className='bg-gray-000 rounded-xl m-3 flex flex-wrap space-x-3 justify-center'>

                    {records.map((post, index) => (<>
                        <PostCard key={index} data={post} likes={likes} setLikes={setLikes} />
                    </>
                    ))}

                </div>
            </div>
        </div>
    );
}




function PostCard({ data, likes, setLikes, key }) {

    const userId = localStorage.getItem('userId');
    console.log('Lhhohoh:', userId);


    const userExists = data.expand ? data?.expand?.like?.some(likeObj => likeObj.user_id === userId) : false;

    console.log('usere exist', userExists);

    return (
        <div className='w-[220px] h-max border border-1 rounded-xl my-5 py-5 px-5 font bg-white shrink-0 flex flex-col justify-center items-center space-y-5 overflow-hidden'>
            <p className='text-md font-semibold'>{data.text}</p>
            
            {data.expand && data.expand.writer_id &&
            <Link to={`/${data.expand.writer_id.id}/${data.expand.writer_id.name}`}>
            <p className='text-xs' >- {data.expand.writer_id.name}</p>
            </Link>

            }
            <div className='flex space-x-2'>
                {data.id && <LikeButton post_id={data.id} user_id={userId} like={data.expand && data.expand.like} userExists={userExists} likes={likes} setLikes={setLikes} />}
            </div>
        </div>
    );
}

















function LikeButton({ user_id, post_id, like, likes, setLikes, userExists }) {

    const [liked, setLiked] = useState(userExists);

    const data = {
        user_id: user_id,
        post_id: post_id
    };


    const setLike = async () => {

        try {
            const likeRecord = await pb.collection('like').create(data);
            const postRecord = await pb.collection('post').update(post_id, { 'like+': [likeRecord.id] });

            setLiked(true);

            setLikes(prevLikes => ({
                ...prevLikes,
                [post_id]: prevLikes[post_id] + 1
            }));
        } catch (error) {
            console.error('Error creating like or updating post:', error);
        }
    };


    const setUnlike = async () => {
        try {
            const userId = localStorage.getItem('userId');
            const likeRecord = await pb.collection('like').getFirstListItem(`user_id="${userId}" && post_id="${post_id}"`,);
            await pb.collection('like').delete(likeRecord.id);
            const postRecord = await pb.collection('post').update(post_id, { 'like-': [likeRecord.id] });

            setLiked(false);

            setLikes(prevLikes => ({
                ...prevLikes,
                [post_id]: prevLikes[post_id] - 1
            }));
        } catch (error) {
            console.error('Error creating like or updating post:', error);
        }
    };


    return (

        <>
            <button className={liked ? 'text-green-500' : 'text-orange-500'} onClick={liked ? setUnlike : setLike}>
                {liked ?  `Unlike (${likes[post_id] || 0})` : `Like (${likes[post_id] || 0})`}
            </button>
        </>
    );




}

export default PostView;
